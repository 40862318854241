import { ITypeTableData } from 'scorer-ui-kit/dist/Tables';

export const MEDIA_DIR = '/data/media/';
export const IMAGE_DIR = '/data/images/';

export const INITIAL_ROWS: ITypeTableData = [
  {
    columns: []
  }
];

export const ALERT_TYPES_LIST = [
  'LISTED_DETECTED',
  'INTRUDER_DETECTED',
  'IMPORTANT_DETECTED',
  'UNKNOWN_DETECTED'
];

export const DETECTION_TYPE_LIST = [
  'NUMBER_PLATE',
  'CAR_COUNTING',
  'FACES',
  'PEOPLE',
  'FACE_IDENTIFICATION',
  'PEOPLE_COUNTING',
  'INTRUSIONS'
]


export const PAGE_SIZE_OPTIONS = [1, 10, 20, 50, 100];

