import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFetchAlertOptions, useAlertsWithoutContext } from '../../hooks/useAlertsWithoutContext';
import styled from 'styled-components';
import { downloadFile, EMPTY_ROWS, getAlertsSimpleTypesList, getAlertTypeLocal, getDetectionTypeLocal, getFilterAlertStatus, getSelected, getTypeIcon } from '../../utils';
import { ActionButtons, DateInterval, FilterBar, IFilterDatePicker, IFilterDropdownConfig, IFilterItem, IFilterResult, TypeTable } from 'scorer-ui-kit';
import { ICameraConfig, useCameraConfig } from '../../hooks/useCameraConfig';
import { useParams } from 'react-router-dom';
import { IParams } from '../../types';
import { Camera } from '../../hooks/useCameras';
import AlertStatusCell from './AlertStatusCell';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import CellInformation from '../atoms/CellInformation';
import Pagination from '../Pagination';
import TableCellRelativeTime from '../atoms/TableCellRelativeTime';
import i18n from '../../i18n';

const Container = styled.div`
  margin: 40px 0 10px 0;
`;

const FilterWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  align-items: baseline;
  margin-bottom: 60px;
`;

const sizeOptions = [10, 20, 50, 100];

interface ISelectedFilterValues {
  [key: string]: DateInterval | string | undefined | Date
}

const initialFilterValues: ISelectedFilterValues = {
  alertTypeDropdown: undefined,
  dateTimeDropdown: undefined,
  alertStatusDropdown: undefined,
  sortDirection: 'asc',
  sortBy: 'datetime',
}

interface IAlertsTab {
  camera?: Camera
}

const AlertsTab: React.FC<IAlertsTab> = () => {

  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const { t } = useTranslation(['CameraDetails', 'Common']);
  const { alerts, alertCount, alertsLoading, actions: { fetchAlerts, dismissAlert } } = useAlertsWithoutContext();
  const { actions: { getCameraConfigsByCameraID } } = useCameraConfig();
  const [cameraConfig, setCameraConfig] = useState<ICameraConfig>();
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);

  const { cameraID }: IParams = useParams();

  const tableColumns: ITableColumnConfig[] = [
    {
      header: t('alertTab.analysisType'),
      cellStyle: 'firstColumn',
      groupTitle: t('alertTab.meta')
    },
    {
      header: t('alertTab.meta'),
      cellStyle: 'normalImportance',
      groupTitle: t('alertTab.meta')
    },
    {
      header: '',
      cellStyle: 'normalImportance',
      groupTitle: t('alertTab.meta')
    },
    {
      columnId: 'datetime',
      header: t('alertTab.when'),
      cellStyle: 'normalImportance',
      sortable: true,
      sortActive: true,
    },
    {
      header: t('alertTab.alertStatus'),
      cellStyle: 'normalImportance'
    }, {
      header: t('alertTab.actions'),
      cellStyle: 'normalImportance'
    }
  ];

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = (alerts ?? []).map((alert) => {

      const {
        datetime,
        alert_type,
        active,
        movie_url,
        image_url,
        detection,
        algorithm
      } = alert;

      const {
        subject_area = '-',
        subject_number = '',
        subject_kana = '',
        subject_user_type = '',
      } = detection||{};

      const row: IRowData = {
        header: {
          image: image_url,
          mediaType: 'video',
          mediaUrl: movie_url,
          icon: getTypeIcon(alert_type, algorithm, detection)
        },
        columns: [
          { text: cameraConfig ? `${getDetectionTypeLocal(cameraConfig.algorithm_type)}` : '-' },
          { customComponent: <CellInformation title={t('alertTab.typeDetected')} subInfo={getAlertTypeLocal(alert_type, algorithm, detection)}/>  },
          { customComponent: <CellInformation title={t('alertTab.numberPlate')} subInfo={`${subject_area}${subject_user_type}  ${subject_kana}${subject_number}`}/>},
          { customComponent: <TableCellRelativeTime eventTime={datetime}/> },
          { customComponent: <AlertStatusCell status={active} alert={alert} dismissAlert={dismissAlert} /> },
          { customComponent: <ActionButtons buttonsConfig={[{ icon: 'DownloadVideo', onClick: () => downloadFile(movie_url), }]} /> }
        ]
      };
      return row;
    });
    return data.length ? data : EMPTY_ROWS;
  }, [alerts, cameraConfig, dismissAlert, t]);

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };

  const  dropdowns: IFilterDropdownConfig[] = [
    {
      id: 'alertTypeDropdown',
      buttonText: t('alertTab.typeDetected'),
      list: getAlertsSimpleTypesList(),
      buttonIcon: 'MetaCategories',
      optionType: 'radio',
      selected: getSelected(selectedFilterValues.alertTypeDropdown) as IFilterItem | undefined
    },
    {
      id: 'alertStatusDropdown',
      buttonText: t('alertTab.alertStatus'),
      list: getFilterAlertStatus(),
      buttonIcon: 'Notifications',
      optionType: 'radio',
      selected: getSelected(selectedFilterValues.alertStatusDropdown) as IFilterItem | undefined
    }
  ];

  const datePickers: IFilterDatePicker[] = [
    {
      id: 'dateTimeDropdown',
      buttonIcon: 'DateTime',
      buttonText: t('alertTab.when'),
      dateMode: 'interval',
      timeMode: 'interval',
      selected: undefined,
      dateTimeTextLower: t('Common:dateTo'),
      dateTimeTextUpper:  t('Common:dateFrom'),
      timeZoneTitle: t('Common:timeZone'),
      lang: i18n.language === 'ja' ? 'ja' : 'en',
    }
  ];


  const getApiParams = useCallback((): IFetchAlertOptions => {

    const { dateTimeDropdown, alertTypeDropdown, alertStatusDropdown, sortDirection = 'asc', sortBy = 'datetime' } = selectedFilterValues;

    const correctedUISort = sortBy === 'datetime'
    ? sortDirection === 'asc' ? 'desc' : 'asc'
    : sortDirection

    return ({
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      startTime: (dateTimeDropdown as DateInterval)?.start,
      endTime: (dateTimeDropdown as DateInterval)?.end,
      alertType: alertTypeDropdown as string,
      active: alertStatusDropdown === undefined ? undefined : alertStatusDropdown === 'active',
      sortBy: sortBy as string,
      sortDirection: correctedUISort as string,
      cameraId: cameraID
    });

  }, [selectedFilterValues, pageSize, currentPage, cameraID]);

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrentPage(parseInt(val));
  }, []);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);

  const updateConfig = useCallback(async () => {
    const config = await getCameraConfigsByCameraID(parseInt(cameraID)) || [];
    if (config[0]) {
      setCameraConfig(config[0]);
    }
  }, [cameraID, getCameraConfigsByCameraID])

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {
    const selectedValues: ISelectedFilterValues = res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {
      selectedValues[id] = ('dateTimeDropdown' === id) ? selected as DateInterval : (selected as IFilterItem).value as string;
      return selectedValues;
    },
      { ...initialFilterValues }
    );
    setCurrentPage(1);
    setSelectedFilterValues({ ...selectedValues });
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(alertCount / pageSize));
  }, [alertCount, pageSize]);

  useEffect(() => {
    setRowData(generateRowData())
  }, [generateRowData])

  useEffect(() => {
    fetchAlerts(getApiParams());
  }, [cameraID, fetchAlerts, getApiParams])

  useEffect(() => {
    updateConfig()
  }, [updateConfig])


  return (
    <Container>
        <FilterWrapper>
        <FilterBar
          searchersConfig={[]}
          dropdownsConfig={dropdowns}
          datePickersConfig={datePickers}
          totalResults={rowData.length === 1 && rowData[0].columns.length === 0 ? 0 : rowData.length}
          onChangeCallback={onChangeFilterValue}
          filtersTitle={t('Common:filter.filters')}
          resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
          resultsDateFormat={'yyyy/MM/dd HH:mm'}
          clearText={t('Common:filter.clearAll')}
        />
      </FilterWrapper>
      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
        isLoading={alertsLoading}
        emptyTableText={t('alertTab.noAlertsFound')}
        loadingText={t('Common:loadingData')}
        hasThumbnail
        hasHeaderGroups
        hasTypeIcon
        sortCallback={sortCallback}
        defaultAscending={ initialFilterValues.sortDirection === 'asc' ? true : false }
      />

      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={sizeOptions}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}
    </Container>
  );
};

export default AlertsTab;