import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'scorer-ui-kit';
import styled from 'styled-components';
import { IFeatures } from '../../hooks/useUserManagement';
import { IForm } from '../../pages/AddEditUser';

const UncheckMessage = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: normal;
  margin-top: 53px;
  color: #8b9196;
`;

const FormContainer = styled.div`
  margin-top: 37px;
`;

const SectionTitle = styled.div`
font-family: ${({ theme }) => theme.fontFamily.ui};
font-size: 16px;
font-weight: 500;
  color: #5a6269;
  display: flex;

  &:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #eee;
    margin: auto;
    margin-left: 10px;
  }
`;

const FeaturesContainer = styled.div`
  margin: 26px 0 52px;
  display: flex;
  gap: 20px 78px;
  flex-wrap: wrap;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 12px;
`;
const CheckboxLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  color: #8b9196;
  cursor: pointer;
`;

interface IFeaturesTab {
  form: IForm,
  onFormChange: (name: string, value: string|boolean|IFeatures) => void
}

const FeaturesTab: FC<IFeaturesTab> = ({form, onFormChange}) => {
  const { t } = useTranslation(['AddEditUser', 'Users', 'Common']);
  const featuresList = [
    {
      title: 'general',
      features: [
        {
          name: 'updatePassword',
          label: 'updatePassword'
        },
        {
          name: 'userManagement',
          label: 'Users:userManagement'
        },
        {
          name: 'numberPlateImport',
          label: 'numberPlateImport'
        },
        {
          name: 'systemExport',
          label: 'systemExport'
        },
      ]
    },
    {
      title: 'camera',
      features: [
        {
          name: 'cameraEdit',
          label: 'cameraEdit'
        },
        {
          name: 'cameraSnapshot',
          label: 'cameraSnapshot'
        },
        {
          name: 'cameraConfig',
          label: 'cameraConfig'
        },
        {
          name: 'cameraFiles',
          label: 'cameraFiles'
        }
      ]
    },
    {
      title: 'detection',
      features: [
        {
          name: 'numberPlateDetection',
          label: 'numberPlateDetection'
        },
        {
          name: 'intrusionDetection',
          label: 'intrusionDetection'
        },
        {
          name: 'faceDetection',
          label: 'faceDetection'
        },
        {
          name: 'faceAnalysis',
          label: 'faceAnalysis'
        },
      ]
    },
    {
      title: 'analysisType',
      features: [
        {
          name: 'carCount',
          label: 'carCount'
        },
        {
          name: 'peopleCount',
          label: 'peopleCount'
        },
      ]
    },
    {
      title: 'rulesAndEvents',
      features: [
        {
          name: 'relayController',
          label: 'relayController'
        },
        {
          name: 'eventPipeline',
          label: 'eventPipeline'
        },
      ]
    },
    {
      title: 'others',
      features: [
        {
          name: 'registerUnknown',
          label: 'registerUnknown'
        }
      ]
    },
  ];
  const [features, setFeatures] = useState<IFeatures>(form.feature);

  useEffect(() =>{
    onFormChange('feature', features);
  }, [features, onFormChange]);

  const onChangeCheckbox = useCallback((name: string, value: boolean) => {
    setFeatures(prev => {
      return {
        ...prev,
        [name]: value
      };
    });
  }, []);

  return (
    <>
      <UncheckMessage>{t('featureUncheckMessage')}</UncheckMessage>
      <FormContainer>
        {featuresList.map(item => {
          return (
            <>
              <SectionTitle>{t(item.title)}</SectionTitle>
              <FeaturesContainer>
                {
                  item.features.map(feature => {
                    return (
                      <CheckboxContainer>
                        <Checkbox checked={features[feature.name]} onChangeCallback={e=> onChangeCheckbox(feature.name, e)} />
                        <CheckboxLabel onClick={() => onChangeCheckbox(feature.name, !features[feature.name])}>{t(feature.label)}</CheckboxLabel>
                      </CheckboxContainer>
                    );
                  })
                }
              </FeaturesContainer>
            </>
          );
        })}
      </FormContainer>
    </>
  )
}

export default FeaturesTab;
